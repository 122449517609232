<template>
  <el-card id="taskulous">
    <header>
      <h1>短信提醒跟进</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>客户号码</span>
            <el-input
              v-model="activePhone"
              placeholder="请输入客户号码"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户姓名</span>
            <el-input
              v-model="name"
              placeholder="请输入客户姓名"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>提醒时间</span>
            <el-date-picker
              v-model="send_time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="small"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="归属人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="客户姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="active_phone"
          label="客户号码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="send_time"
          label="提醒时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          label="备注"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="status" label="状态" align="center">
              <template #default="scope">
                <span v-if="scope.row.status == 0">未发送</span>
                <span v-if="scope.row.status == 1">已发送</span>
              </template>
            </el-table-column> -->
        <el-table-column label="操作" width="200" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="small"
              >编辑</el-button
            >
            <!-- 删除号码 -->
            <el-button type="danger" @click="delButton(scope)" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>

    <!-- 删除弹出框 -->
    <el-dialog title="删除" :visible.sync="delVisible" width="30%">
      <span>确定删除这段号码吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="delVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitdelValue()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="updateVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="提醒时间" prop="sendTime">
          <el-date-picker
            v-model="form.sendTime"
            type="datetime"
            placeholder="选择提醒时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            v-model="form.remarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer style="position: relative">
        <span class="dialog-footer">
          <el-button type="primary" @click="updateVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { reminderDel, reminderUpdate, reminderList } from "@/api/reminder";
let token = localStorage.getItem("TOKEN");
export default {
  data() {
    return {
      delVisible: false,
      id: null,
      form: {
        remarks: null,
        name: null,
        send_time: null,
        id: null,
        active_phone: null,
      },
      updateVisible: false,
      active_phone: null,
      activePhone: null,
      name: null,
      status: null,
      create_time: null,
      send_time: null,
      options1: [
        {
          status: 0,
          label: "未发送",
        },
        {
          status: 1,
          label: "已发送",
        },
      ],
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 删除
    delButton(row) {
      this.delVisible = true;
      this.form.id = row.row.id;
    },
    // 删除确定
    submitdelValue() {
      let params = {
        id: this.form.id,
      };
      let res = reminderDel(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.delVisible = false;
        this.getList();
      });
    },
    // 编辑
    update(row) {
      this.updateVisible = true;
      this.form.id = row.row.id;
      this.form.active_phone = row.row.active_phone;
      this.form.name = row.row.name;
      this.form.send_time = row.row.send_time;
      this.form.remarks = row.row.remarks;
    },
    // 编辑确定
    updateSubmit() {
      let sendTime = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.form.sendTime);
      let params = {
        id: this.form.id,
        name: this.form.name,
        sendTime,
        active_phone: this.form.active_phone,
        remarks: this.form.remarks,
      };
      let res = reminderUpdate(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.updateVisible = false;
        this.getList();
      });
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    getList(obj) {
      // console.log("展示", this.send_time);
      let params = {
        page: this.page,
        size: this.size,
        remarks: this.remarks,
        // id:this.id,
        // send_time: this.send_time,
      };
      if (obj) {
        params.activePhone = obj.activePhone;
        params.name = obj.name;
        // params.status = obj.status;
        params.sta = obj.sta;
        params.end = obj.end;
      }
      let res = reminderList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.tableData.forEach((v) => {
          v.create_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", v.create_time);
          v.send_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", v.send_time);
        });
        // console.log("table数据", this.tableData);
        this.pageTotal = res.data.total;
      });
    },
    // 查询
    handleSearch() {
      this.page = 1;
      // console.log("提示时间", this.send_time);
      let obj = {
        activePhone: this.activePhone,
        name: this.name,
      };
      this.getList(obj);
    },
    // 重置
    reset() {
      this.activePhone = null;
      this.name = null;
      // this.status = null;
      this.send_time = null;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
#taskulous {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>